import { ref } from 'vue';
import { defineStore } from 'pinia';

import { useApi } from '@/composables/useApi';

export default defineStore('agent/jobs', () => {
	const jobListing = ref(null);

	async function get() {
		jobListing.value = null;
		const result = await useApi('jobs/', {
			v3Agent: true,
			message: 'There was an issue retrieving the job listings. Please try again later.',
		}).json();
		jobListing.value = result.data?.value;
	}

	async function update(url) {
		try {
			const encoded_url = new URL(url);
			const response = await useApi('jobs/', {
				v3Agent: true,
				message:
					'There was an issue with updating your job listing. Please try again later.',
			})
				.post({ url_override: encoded_url.href })
				.json();
			jobListing.value = response.data?.value;
		} catch (error) {
			return Promise.reject(error);
		}
	}

	async function crawl() {
		try {
			const response = await useApi('jobs/crawl/', {
				v3Agent: true,
				message:
					'There was an issue with updating your job listing. Please try again later.',
			})
				.post({})
				.json();
			jobListing.value = response.data?.value;
		} catch (error) {
			console.error(error);
			throw error;
		}
	}

	return {
		jobListing,

		get,
		update,
		crawl,
	};
});
