import { defineStore } from 'pinia';
import { useRoute } from 'vue-router/composables';

import { useApi } from '@/composables/useApi';

import { ref, watchEffect, computed } from 'vue';
import useDomainsStore from '@/stores/agent/agent-domains';
import useAlertStore from '@/stores/alerts';
import useEmailStore from '@/stores/agent/agent-emails';
import usePaymentStore from '@/stores/agent/agent-payment';
import useAdwordsStore from '@/stores/agent/agent-adwords';
import useStaffStore from '@/stores/agent/agent-staff';
import useQlpStore from '@/stores/agent/agent-qlp';
import useCalltrackingStore from '@/stores/agent/agent-adwords';
import useBillingStore from '@/stores/agent/agent-billing';
import useJobsStore from '@/stores/agent/agent-jobs';

export default defineStore('agent', () => {
	const VueRoute = useRoute();
	const domainsStore = useDomainsStore();
	const emailStore = useEmailStore();
	const alertStore = useAlertStore();
	const paymentStore = usePaymentStore();
	const adwordsStore = useAdwordsStore();
	const staffStore = useStaffStore();
	const qlpStore = useQlpStore();
	const calltrackingStore = useCalltrackingStore();
	const billingStore = useBillingStore();
	const jobsStore = useJobsStore();

	const agent = ref(null);
	const progress = ref(0);
	const tosAcceptedOn = ref(null);
	const services = ref(null);
	const notifications = ref(null);
	const siteNotifications = ref(null);
	const loadingAgentData = ref(false);

	const micrositeUrl = computed(
		() =>
			`https://www.statefarm.com/agent/us/xx/_/sf-${VueRoute?.params?.agentID?.toLowerCase()}`
	);

	const isIntern = computed(() => agent.value?.is_agent_intern);

	async function getDashData() {
		const agentID = VueRoute.params?.agentID ?? undefined;

		const fetcher = useApi(`dash/`, {
			v3Agent: true,
			message: `Could not retrieve dash data for id ${agentID}! Please try again later.`,
		});
		const result = await fetcher.json();

		tosAcceptedOn.value = result?.data?.value?.tos_accepted_on;
		services.value = result?.data?.value?.services;
	}

	async function getAgentData() {
		const agentID = VueRoute.params?.agentID ?? undefined;

		if (!agentID) {
			alertStore.addAlert({
				message: `Associate ID is undefined!`,
			});
			return Promise.reject('Associate ID is required but not defined.');
		}

		try {
			progress.value = 0;
			loadingAgentData.value = true;

			await onReset();
			const dashDataPromise = getDashData();
			const notificationsPromise = reloadNotifications();

			const fetcher = useApi(``, {
				v3Agent: true,
				message: `Could not retrieve data for id ${agentID}! Please try again later.`,
			});

			watchEffect(() => {
				progress.value = fetcher.progress;
			});

			const result = await fetcher.json();

			agent.value = result?.data?.value;

			return Promise.all([dashDataPromise, notificationsPromise]);
		} catch (e) {
			return Promise.reject(e);
		} finally {
			loadingAgentData.value = false;
		}
	}

	async function ensureAgentData() {
		if (agent.value) {
			return Promise.resolve();
		} else {
			try {
				await getAgentData();
				return Promise.resolve();
			} catch (error) {
				console.error(error);
				return Promise.reject();
			}
		}
	}

	async function reloadNotifications() {
		try {
			const { data } = await useApi('notifications/', { v3Agent: true }).json();
			notifications.value = data.value;
		} catch {
			alertStore.addAlert({
				message: `We couldn't reload your notifications page! Please try again later.`,
			});
		}
	}

	async function dismissNotification({ note_id }) {
		await useApi(`notifications/${note_id}/`, {
			v3Agent: true,
			message: 'There was an issue dismissing the notification! Please try again later.',
		}).delete();
		await reloadNotifications();
	}

	async function expandNotification({ note_id }) {
		await useApi('account/expand_notification/', {
			agent: true,

			message: 'There was an issue expanding the notification! Please try again later.',
		}).post({ note_id });
		await reloadNotifications();
	}

	function onReset() {
		adwordsStore.$reset();
		alertStore.$reset();
		billingStore.$reset();
		calltrackingStore.$reset();
		domainsStore.$reset();
		emailStore.$reset();
		jobsStore.$reset();
		paymentStore.$reset();
		qlpStore.$reset();
		staffStore.$reset();
	}

	return {
		progress,
		agent,
		isIntern,
		tosAcceptedOn,
		services,
		notifications,
		siteNotifications,
		loadingAgentData,
		micrositeUrl,

		getAgentData,
		ensureAgentData,
		reloadNotifications,
		dismissNotification,
		expandNotification,
		onReset,
	};
});
